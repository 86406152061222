<template>
	<div>		
		<dashboard-desktop />		
	</div>
</template>
<script>		
	import DashboardDesktop from './DashboardDesktop';

	export default {
		components: {			
			DashboardDesktop,
		},
		mounted(){
		}
	}
</script>