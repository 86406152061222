<template>
	<main class="markings">
		
		<header-desktop></header-desktop>

		<div class="content-title-default-mobile d-lg-none">
			<router-link :to="{name: 'home'}">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.153" height="13.639" viewBox="0 0 15.153 13.639">
					<path
						d="M8.27,107.648l-2.8-2.8h8.66a1.026,1.026,0,0,0,1.026-1.026v-1.026a1.026,1.026,0,0,0-1.026-1.026H5.467l2.8-2.8a1.026,1.026,0,0,0,0-1.451l-.726-.726a1.026,1.026,0,0,0-1.451,0L.3,102.579a1.026,1.026,0,0,0,0,1.451l5.794,5.794a1.026,1.026,0,0,0,1.451,0l.726-.726a1.026,1.026,0,0,0,0-1.451Z"
						transform="translate(0.001 -96.485)" fill="#005aa1" />
				</svg>
				<h2>Meus pedidos:</h2>
			</router-link>
		</div>

		<section class="content-markings">
			<div class="content-markings-itens container">
				<div class="content-calendar">
					<div class="content-title-default d-none d-lg-block">
						<h2>Calendário</h2>
					</div>
					<div class="content-calendar-body">
						<calendar v-if="showCalendar"></calendar>		
					</div>
				</div>
				<div class="content-schedules">
					<schedules-orders></schedules-orders>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
	import HeaderDesktop from '@/components/HeaderDesktop';	
	import Calendar from '@/components/Calendar';
	import SchedulesOrders from '@/components/SchedulesOrders';
	import {mapActions} from 'vuex';

	export default {
		data(){
			return {
				showCalendar: false,				
			}
		},
		components:{
			HeaderDesktop,
			Calendar,
			SchedulesOrders,
		},
		methods: {
			...mapActions('Order', ['getOrders']),
		},		
		async mounted(){
			await this.getOrders({
				date: [
					this.$moment().format("YYYY-MM-DD"),
					this.$moment().add('days', 30).format("YYYY-MM-DD")
				]
			});

			this.showCalendar = true;
		}
	}
</script>