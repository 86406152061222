var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-schedules-div"},[_vm._m(0),_c('div',{staticClass:"content-schedules-body"},[(_vm.loadingBook)?_vm._l((4),function(n){return _c('div',{key:n,staticClass:"content-schedules-body-elements"},[_c('div',{staticClass:"foods"},[_c('div',{staticClass:"foods-image"},[_c('PuSkeleton',{attrs:{"circle":"","height":"67px"}})],1),_c('div',{staticClass:"foods-text"},[_c('h3',[_c('PuSkeleton')],1),_c('p',[_c('PuSkeleton')],1)])])])}):_vm._l((_vm.orders),function(orderRow,rowIndex){return _c('div',{key:rowIndex},[_c('div',{staticClass:"content-schedules-header"},[_c('h2',[_vm._v(_vm._s(rowIndex))])]),_vm._l((orderRow),function(order,orderIndex){return _c('div',{key:orderIndex,staticClass:"content-schedules-body-elements"},[_c('h2',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("moment")(order.date_at,"DD"))+" "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(order.date_at,"MMM"))+" ")])]),_c('div',{staticClass:"foods"},[_c('div',{staticClass:"foods-image"},[(order.menu_product.image_color)?_c('img',{staticClass:"img-fluid",attrs:{"src":order.menu_product.image_color,"alt":"image","title":"image"}}):_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/schedules/prato-personalizado.png"),"alt":"image","title":"image"}})]),_c('div',{staticClass:"foods-text"},[(order.menu)?_c('h3',[_vm._v(" "+_vm._s(order.menu.name)+" "),(_vm.$moment(order.date_at).isAfter(_vm.$moment()))?_c('span',{staticClass:"badge badge-danger",on:{"click":function($event){_vm.deleteOrder({
                    order_id: order.id,
                    date: [
                      _vm.$moment().format('YYYY-MM-01'),
                      _vm.$moment().endOf('month').format('YYYY-MM-DD'),
                    ],
                  })}}},[_vm._v("Remover")]):_vm._e()]):(order.menu_product)?_c('h3',[_vm._v(" "+_vm._s(order.menu_product.name)+" "),(_vm.$moment(order.date_at).isAfter(_vm.$moment()))?_c('span',{staticClass:"badge badge-pill badge-danger",on:{"click":function($event){_vm.deleteOrder({
                    order_id: order.id,
                    date: [
                      _vm.$moment().format('YYYY-MM-01'),
                      _vm.$moment().endOf('month').format('YYYY-MM-DD'),
                    ],
                  })}}},[_vm._v("Remover")]):_vm._e()]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.getTextProducts(order)))])])])])})],2)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-schedules-header"},[_c('h2',[_vm._v("Meus Pedidos: ")])])
}]

export { render, staticRenderFns }