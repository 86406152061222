<template>
  <div class="content-schedules-div">
    <div class="content-schedules-header">
      <h2>Meus Pedidos: </h2>
    </div>
    <div class="content-schedules-body">
      <template v-if="loadingBook">
        <div v-for="n in 4" :key="n" class="content-schedules-body-elements">
          <div class="foods">
            <div class="foods-image">
              <PuSkeleton circle height="67px"></PuSkeleton>
            </div>
            <div class="foods-text">
              <h3>
                <PuSkeleton></PuSkeleton>
              </h3>
              <p>
                <PuSkeleton></PuSkeleton>
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>

        <div v-for="(orderRow, rowIndex) in orders" :key="rowIndex">
          <div class="content-schedules-header">
            <h2>{{ rowIndex }}</h2>
          </div>
          <div v-for="(order, orderIndex) in orderRow" :key="orderIndex" class="content-schedules-body-elements">
            <h2 class="date">
              {{ order.date_at | moment("DD") }}
              <span>{{ order.date_at | moment("MMM") }} </span>
            </h2>
            <div class="foods">
              <div class="foods-image">
                <img v-if="order.menu_product.image_color" class="img-fluid" :src="order.menu_product.image_color"
                  alt="image" title="image" />
                <img v-else class="img-fluid" src="@/assets/img/schedules/prato-personalizado.png" alt="image"
                  title="image" />
              </div>
              <div class="foods-text">
                <h3 v-if="order.menu">
                  {{ order.menu.name }}


                  <span v-if="$moment(order.date_at).isAfter($moment())" @click="
                    deleteOrder({
                      order_id: order.id,
                      date: [
                        $moment().format('YYYY-MM-01'),
                        $moment().endOf('month').format('YYYY-MM-DD'),
                      ],
                    })
                    " class="badge badge-danger">Remover</span>
                </h3>
                <h3 v-else-if="order.menu_product">
                  {{ order.menu_product.name }}
                  <span @click="
                    deleteOrder({
                      order_id: order.id,
                      date: [
                        $moment().format('YYYY-MM-01'),
                        $moment().endOf('month').format('YYYY-MM-DD'),
                      ],
                    })
                    " v-if="$moment(order.date_at).isAfter($moment())"
                    class="badge badge-pill badge-danger">Remover</span>
                </h3>
                <p>{{ getTextProducts(order) }}</p>
              </div>
            </div>
          </div>
        </div>

      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("Order", {
      loadingBook: "loading",
      orders: "orders",
    }),
  },
  methods: {
    ...mapActions("Order", ["deleteOrder"]),
    getTextProducts(order) {
      let text = [];
      if (typeof order.order_payload != "string") {
        if (Array.isArray(order.order_payload)) {
          text = order.order_payload.map((p) => {
            if ("produto" in p) {
              return p.produto.descricao;
            }
          });
        } else {
          //por vezes o objeto pode vir com as chaves fora de ordem, por isso é necessário fazer um map para ordenar as chaves
          let newMapped = Object.keys(order.order_payload).map((key) => {
            return order.order_payload[key];
          });

          text = newMapped.map((p) => {
            if ("produto" in p) {
              return p.produto.descricao;
            }
          });
        }
      }

      return text.join(", ");
    },
  },
};
</script>
<style scoped>
.badge {
  font-size: 12px;
  cursor: pointer;
}
</style>