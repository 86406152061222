<template>
	<div>		
		<dashboard-orders />		
	</div>
</template>
<script>		
	import DashboardOrders from './DashboardOrders';

	export default {
		components: {			
			DashboardOrders,
		},
		mounted(){
		}
	}
</script>